<template>
  <v-img
    :key="image"
    position="top"
    class=" hidden-md-and-up"
    :src="image"
    gradient="to bottom, rgba(0,117,131,.9), rgba(0,117,131,.5)"
    height="200px"
  />
</template>
<script>
  export default {
    computed: {

      image () {
        return '/static/' + (this.$route.meta && this.$route.meta.hero ? this.$route.meta.hero : this.$route.name) + '-hero-mobile.jpg'
      },
    },

    methods: {
      isMobile () {
        if (screen.width <= 960) {
          return true
        } else {
          return false
        }
      },
    },

  }
</script>
